// Image
import "../css/pages/termsAndConditions.css";
import { Header, Footer } from "../components/index";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  return (
    <>
      <Header />
      <div className="termsAndConditionsPage">
        <div className="container paddingTopbottom">
          <h1>Cookie Policy</h1>

          <p>
            This Cookie Policy explains how we use cookies on our website, what
            cookies are, why we use them, and how you can manage your cookie
            preferences.
          </p>

          <h2>1. What Cookies Are Used</h2>
          <p>We use the following types of cookies on our website:</p>
          <ul>
            <li>
              <strong>Strictly Necessary Cookies:</strong> These cookies are essential for the
              proper functioning of the website, such as maintaining user
              sessions and providing secure access to parts of the site.
            </li>
            <li>
              <strong>Performance Cookies:</strong> These cookies collect information about how
              users interact with the website, including pages visited, links
              clicked, and time spent on the site. They help us improve the
              performance of the website.
            </li>
            <li>
            <strong>Functionality Cookies:</strong> These cookies allow the website to remember
              user preferences (such as language settings or login details) to
              improve your user experience.
            </li>
            <li>
            <strong>Targeting/Advertising Cookies:</strong> These cookies are used to deliver
              relevant advertisements to users based on their behavior and
              interests, often by tracking user activity across websites.
            </li>
            <li>
            <strong>Analytics Cookies:</strong> We use analytics cookies to track and measure
              website traffic, analyze patterns, and optimize the content and
              structure of our website.
            </li>
          </ul>

          <h2>2. Why Cookies Are Used</h2>
          <p>Cookies are used for the following purposes:</p>
          <ul>
            <li></li>
            <li>
            <strong>Analytics:</strong> We use cookies to gather data and insights about how
              visitors use our website. This helps us to analyze trends,
              identify areas for improvement, and enhance the user experience.
            </li>
            <li>
            <strong>Advertising:</strong> Cookies are used to deliver personalized
              advertisements that match your interests. They track your browsing
              activity across websites, ensuring the ads you see are relevant to
              you.
            </li>
            <li>
            <strong>Performance and Functionality:</strong> Some cookies enhance your
              experience on our website by remembering your preferences and
              ensuring that pages load quickly and correctly.
            </li>
          </ul>

          <h2>3. Managing Cookies</h2>
          <p>
            You have control over how cookies are used on your device. Here’s
            how you can manage your cookie preferences:
          </p>
          <ul>
            <li>
            <strong>Browser Settings:</strong> You can adjust your browser settings to accept
              or reject cookies. Most browsers allow you to set preferences for
              cookies, including blocking or deleting cookies after visiting a
              website.
            </li>
            <li>
            <strong>Cookie Consent Banner:</strong> Our website will prompt you with a cookie
              consent banner when you first visit. You can manage your cookie
              preferences directly through this banner by selecting the options
              that best suit you.
            </li>
            <li>
            <strong>Third-Party Tools:</strong> We also offer links to third-party cookie
              management tools for greater control over cookies used for
              analytics and advertisements.
            </li>
          </ul>

          <Link to={"/"} className="getGackToTheRegistrationPage">
            Go back to the Home page
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CookiePolicy;
