import { HelmetProvider } from "react-helmet-async";
import { Routes, Route } from "react-router-dom";

import {
  Analytics,
  CanonicalLink,
  CookiebotComponent,
  LinkedInInsightTag,
  ScrollToTop
} from "./utils/index";

import {
  ComingSoon,
  OffPlanProperties,
  BuyPage,
  SellPage,
  JoiningForm,
  TermsAndConditions,
  PrivacyPolicy,
  CookiePolicy,
  NotFoundURL,
} from "./page/index";
import {
  BuyingAndSelling,
  Buyer,
  Seller,
  Agent,
} from "./componentsFormRegistration/index";
import "./css/main.css";

function App() {
  return (
    <HelmetProvider>
      <CanonicalLink />
      <Analytics />
      {/* <AnalyticsTracker /> */}
      <CookiebotComponent />
      <LinkedInInsightTag />
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/buy" element={<BuyPage />} />
        <Route path="/sell" element={<SellPage />} />
        <Route path="/off-plan-properties" element={<OffPlanProperties />} />
        <Route path="/joiningForm" element={<JoiningForm />} />
        <Route
          path="/registration/buying-and-selling"
          element={<BuyingAndSelling />}
        />
        <Route path="/registration/buyer" element={<Buyer />} />
        <Route path="/registration/seller" element={<Seller />} />
        <Route path="/registration/agent" element={<Agent />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="*" element={<NotFoundURL />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
