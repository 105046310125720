import { useEffect } from 'react';

const CookiebotComponent = () => {
  const REACT_APP_COOKIESBOT_TRACKING_ID = process.env.REACT_APP_COOKIESBOT_TRACKING_ID;
 
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://consent.cookiebot.com/uc.js';
    script.async = true;
    script.setAttribute('data-cbid', REACT_APP_COOKIESBOT_TRACKING_ID); 
    script.setAttribute('data-blockingmode', 'auto');
    script.setAttribute('data-culture', 'en');

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [REACT_APP_COOKIESBOT_TRACKING_ID]);  

  return null;
};

export default CookiebotComponent;
