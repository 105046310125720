import { useEffect } from "react";

const Analytics = () => {
  const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

  useEffect(() => {
    if (!GA_TRACKING_ID) {
      console.error("GA_TRACKING_ID is not defined");
      return;
    }

    if (!window.__GA_INITIALIZED__) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      document.head.appendChild(script);

      const scriptConfig = document.createElement("script");
      scriptConfig.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GA_TRACKING_ID}');
      `;
      document.head.appendChild(scriptConfig);

      window.__GA_INITIALIZED__ = true;
    }
  }, [GA_TRACKING_ID]);

  return null;
};

export default Analytics;
